import { Component } from '@angular/core';

@Component({
  selector: 'privacy-policy',
  templateUrl: './privacy-policy.component.html'
})
export class PrivacyPolicyComponent {
  
  ngOnInit() {
    window.location.href = 'https://www.dropbox.com/scl/fi/wrwi91a7vfaj4toyxwnr3/KSG-Privacy-Policy.pdf?rlkey=gan9ptvan33pi0gokkv8qw2i5&st=8yma0vfy&dl=0';
   
  }
}




