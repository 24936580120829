import { Component } from '@angular/core';

@Component({
  selector: 'home',
  templateUrl: './home.component.html'
})
export class HomeComponent {

  name: string;
  email: string;
  phone: string;
  message: string;
  optin: boolean;
  optout: boolean;

  ngOnInit() {


  }

  sendContactInfo() {

    if (!this.name) {
      alert('Please enter Name');
      return;
    }

    if (!this.email) {
      alert('Please enter Email');
      return;
    }

    if (!this.message) {
      alert('Please enter Message');
      return;
    }

    if (!this.optin && !this.optout) {
      alert('Please select opt in preference');
      return;
    }

    this.name = '';
    this.email = '';
    this.phone = '';
    this.message = '';

    alert('Thank you for contacting us.  We will be in touch soon!');
  }

  onOptIn() {
    this.optout = false;
    this.optin = true;
  }

  onOptOut() {
    this.optin = false;
    this.optout = true;
  }
}




