<div fxLayout="row" class="header">
  <div fxFlex="50%">
    <a href="">
      <img src="./assets/images/siteLogo.png" style="height:175px" alt="Kreative Solutions Group" />
    </a>
  </div>
  <div fxFlex="50%" fxLayoutAlign="end center">
    <div fxLayout="row wrap">
      <a target="_blank" href="https://www.linkedin.com/company/1694216/admin/">
        <i class="fab fa-linkedin fa-3x" style="color:#0077b5;"></i>
      </a>
    </div>
  </div>
</div>

<div fxLayout="row" class="body">
  <div fxFlex="100%">
    <div fxLayout="row">
      <!-- <div fxFlex="10%">
            </div> -->
      <div fxFlex="100%" class="wrapper">
        <h4 class="padBottom20">Remote Software Consultants</h4>
        Did you know that in software development you can easily evaluate the quality of a developer by their work?
        That's the nice part... if the output of a developer is not good, it quickly shows, regardless of where they
        work. With all the communication streams available over the web these days, it's never been a better time to go
        remote and start saving major business overhead expenses without sacrificing quality of work output.
        <br />
        <br />
        Email us today for more information: <a
          href="mailto:info@kreativesolutionsgroup.com">info@kreativesolutionsgroup.com</a>
      </div>
      <!-- <div fxFlex="10%">
            </div> -->
    </div>
  </div>
</div>

<div fxLayout="row wrap" class="body" fxLayoutAlign="center start">
  <div fxFlex="33" fxFlex.md="33" fxFlex.sm="50" fxFlex.xs="100" fxLayout="column" class="card">
    <mat-card>
      <img mat-card-image src="/assets/images/geo.jpg" />
      <mat-card-title>
        <h4>Technology</h4>
      </mat-card-title>
      <mat-card-content>We specialize in but not limited to: Microsoft .NET, Angular, React, Azure, AWS, Java, Dev Ops,
        etc.</mat-card-content>
    </mat-card>
  </div>
  <div fxFlex="33" fxFlex.md="33" fxFlex.sm="50" fxFlex.xs="100" fxLayout="column" class="card">
    <mat-card>
      <img mat-card-image src="/assets/images/office.jpg" />
      <mat-card-title>
        <h4>Quality</h4>
      </mat-card-title>
      <mat-card-content>Getting an application you hoped for can sometimes be a challenge.Spending a lot of money on
        something that turns out to be under par is often disappointing. We work hard with our clients to document
        the exact requirements you need so no expectation is unmet.When you're not happy,
        we're not happy.<br /><br />Feel comfortable knowing we are here to give
        you a product that is pleasing and meets all needs.</mat-card-content>
    </mat-card>
  </div>
  <div fxFlex="33" fxFlex.md="33" fxFlex.sm="50" fxFlex.xs="100" fxLayout="column" class="card">
    <mat-card>
      <img mat-card-image src="/assets/images/lamp.jpg" />
      <mat-card-title>
        <h4>Reliable</h4>
      </mat-card-title>
      <mat-card-content>In the world of IT and software development, things are fast paced and high demand. When you
        decide to hire someone, you want to know it will get done, be under budget and not have
        to worry about micro-managing the task at hand. This is our main objective
        at Kreative Solutions Group. <br /><br />We offer the most reliable developers and development solutions
        so you feel comfortable building long lasting business relationships
        with us. We hope to build a lifetime of success with you.</mat-card-content>
    </mat-card>
  </div>
</div>

<div fxLayout="row" class="body" style="background:#eeeeee;">
  <div fxFlex="100%">
    <div fxLayout="row">
      <!-- <div fxFlex="10%">
            </div> -->
      <div fxFlex="100%" class="wrapper">
        <h4 class="padBottom20">Contact Us</h4>
        Name<br/>
        <input type="text" [(ngModel)]="name" />
        <br />
        <br />
        Email<br/>
        <input type="text" [(ngModel)]="email" />
        <br />
        <br />
        Phone Number<br/>
        <input type="text" [(ngModel)]="phone" />
        <br />
        <br />
        Message<br />
        <textarea [(ngModel)]="message" ></textarea>
        <br />
        <br />
        <b>Opt-Out</b> <input type="checkbox" (click)="onOptIn()">
        Yes, I agree to receive text messages from Kreative Solutions Group at the phone number listed above.
        <br /><br />
        Message frequency varies and may include reminders, service or order information. Message and data rates may
        apply. Opt out at any time by replying 'stop' or 'unsubscribe'.
        <br />
        <br />
        <b>Opt-Out</b> <input type="checkbox" (click)="onOptOut()"/>
        No, I do not want to receive text messages from Kreative Solutions Group
        <br /> <br />
        See our <a
          href="https://drive.google.com/file/d/1xIdhj6Qc6gTunrLFKU1ZA4vuQe1YTfdZ/view?usp=drive_link"
          target="_blank">Privacy Policy</a> for details on how we handle your
        information.
        <br /><br />
        <div>
          <button (click)="sendContactInfo()">Submit</button>
        </div>
      </div>
      <!-- <div fxFlex="10%">
            </div> -->
    </div>
  </div>
</div>

<div fxLayout="row" class="footer">
  <div fxFlex="100%">
    <div fxLayout="row">
      <div fxFlex="10%">
      </div>
      <div fxFlex="80%" class="wrapper">
        <div fxLayoutAlign="center center" class="padBottom20">
          2977 Highway K Suite 149, O'Fallon, MO 63368
        </div>
        <div fxLayoutAlign="center center">
          <a href="mailto:info@kreativesolutionsgroup.com">info@kreativesolutionsgroup.com</a>&nbsp;| 636.333.0216
        </div>
      </div>
      <div fxFlex="10%">
      </div>
    </div>
  </div>
</div>